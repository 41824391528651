import Vue from 'vue'
import App from './App.vue'
import router from '@/router';
import vuetify from './plugins/vuetify'
import store from './store';
import proxyHandler from '@/plugins/proxyHandler';
import '@/plugins/vue-toast';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.prototype.$axios = proxyHandler;
const EventBus = new Vue();
export default EventBus;

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');

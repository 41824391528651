import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from  './auth';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
      key: 'tetra-ui-monitor'
  })],
  state: {
    // back
    version: '0.0.0',
    robot_namespace: 'TETRA_NS',

    // db
    map_id: 1,
    base_speed: 0.3,
    servo_state: 1,
    docking_battery: 30,
    docking_battery_max: 80,
    language: 'korean',
    model: 'm',
    only_home_docking: 0,
    use_gpio: 0,
    ros_collision_circle_radius: 1,
    jog_front_speed: 0.4,
    jog_back_speed: 0.2,
    jog_rotation_speed: 0.3,
    use_preview: 0,

    // front
    init: false,
    socketConnected: false,
    isTaskStop: false,
  },
  mutations: {
    setData(state, payload){
      state[payload.name]= payload.value;
    },
    stopTask(state) {
      state.isTaskStop = true;
    },
    reStartTask(state) {
      state.isTaskStop = false;
    }
  },
  getters: {

  },
  actions: {

  },
  modules: {
    users: auth,
  },
});

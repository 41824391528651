<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <span class="modal-text"> {{ message }} </span>
            </slot>
          </div>

          <v-row class="modal-footer ma-0 pa-0">
            <template v-if="action === 'no-action'" />
            <template v-else-if="action === null">
              <v-col
                class="btn-action pa-0"
                cols="12"
                type="button"
                @click="$emit('close')"
              >
                확인
              </v-col>
            </template>
            <template v-else>
              <template v-if="cancelMessage === null">
                <v-col
                  class="btn-cancel pa-0"
                  cols="6"
                  type="button"
                  @click="$emit('close')"
                >
                  취소
                </v-col>
              </template>
              <template v-else>
                <v-col
                  class="btn-cancel pa-0"
                  cols="6"
                  type="button"
                  @click="$emit('close')"
                >
                  {{ cancelMessage }}
                </v-col>
              </template>
              <v-col
                class="btn-action pa-0"
                cols="6"
                type="button"
                @click.once="$emit('action')"
              >
                {{ action }}
              </v-col>
            </template>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'DefaultModal',
    props: {
      message: {
        type: String,
        default: null
      },
      action: {
        type: String,
        default: null
      },
      cancelMessage: {
        type: String,
        default: null
      },
    }
  }
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  word-break: keep-all;
}

.modal-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  height: 50%;
  margin: 0px auto;
  background-color: #393939;
  border-radius: 10px;
  transition: all .3s ease;
}

.modal-body {
  width: 100%;
  height: 35vh;
  padding: 12vh 0 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  text-align: center;
  color: #ffffff;
}

.modal-text {
  margin: 50% 0 0;
}

.btn-cancel {
  text-align: center;
  background-color: #c4c4c4;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.btn-action {
  text-align: center;
  background-color: #7c7c7c;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>

const routes = [
  {
    path: '/',
    component: () => import('@/views/index'),
  },
  {
    path: '/config',
    component: () => import('@/views/config'),
  },
  {
    path: '/mapping',
    component: () => import('@/views/mapping'),
  },
  {
    path: '/mapinfo',
    component: () => import('@/views/mapinfo'),
  },
  {
    path: '/mapEdit',
    name: 'MapEdit',
    component: () => import('@/views/mapEdit'),
    props: true
  },
  {
    path: '/time',
    component: () => import('@/views/time'),
  },
  {
    path: '/location-info',
    component: () => import('@/views/location/locationinfo'),
  },
  {
    path: '/robot-setting',
    component: () => import('@/views/robotSetting'),
  },
  {
    path: '/new-location',
    component: () => import('@/views/location/newLocations/newLocation'),
    children: [
      {
        path: '/',
        component: () => import('@/views/location/newLocations/config')
      },
      {
        path: 'map',
        component: () => import('@/views/location/newLocations/map')
      },
      {
        path: 'robot',
        component: () => import('@/views/location/newLocations/robot')
      },
      {
        path: 'modify-location',
        name: 'ModifyLocation',
        component: () => import('@/views/location/newLocations/modifyLocation'),
        props: true
      },
    ]
  },
  {
    path: '/wall',
    component: () => import('@/views/location/wall'),
  },
  {
    path: '/area',
    component: () => import('@/views/location/area'),
  },
  {
    path: '/task',
    component: () => import('@/views/task/task'),
    children: [
      {
        path: '/',
        component: () => import( '@/views/task/job' ),
      },
      {
        path: 'work',
        component: () => import( '@/views/task/work' ),
      },
      {
        path: 'plan',
        name: 'Plan',
        component: () => import( '@/views/task/plan' ),
        props: true
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/signIn'),
  },
  {
    path: '/admin-config',
    component: () => import('@/views/adminConfig'),
  },
  {
    path: '/admin-setting',
    component: () => import('@/views/adminSetting.vue'),
  },
  {
    path: '/admin-testing',
    component: () => import('@/views/adminTesting.vue'),
  },
  {
    path: '/admin-calibration',
    component: () => import('@/views/adminCalibration.vue'),
  },
];

export default routes;

import axios from 'axios';

let baseURL = process.env.VUE_APP_API_HOST;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
});

export default axiosInstance;

import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '../store';

Vue.use(Router);

const router = new Router({
  routes,
});

router.beforeEach(async (to, from, next) => {
  switch (to.path) {
    case '/login': break;
    case '/admin-testing':
    case '/admin-config':
    case '/admin-calibration':
    case '/admin-setting': {
      const user = store.getters['users/user'];
      if (user) break;

      await store.dispatch('users/logOut');
      await router.push('/login');
      break;
    }
    default: {
      if(process.env.VUE_APP_LOGIN !== 'Y') break;

      const user = store.getters['users/user'];
      if (user) break;

      await store.dispatch('users/logOut');
      await router.push('/login');
    }
  }

  next();
});

export default router;
